<template>
  <div class="container-fuild">
    <div class="card">
      <div class="card-body">
        <div class="row mb-2 ">
          <div class="col-12 col-sm-6 col-xl-5">
            <div class="row mt-1">
              <div class="col-12 col-sm-6">
                <b-form-group
                  label="เริ่มต้น"
                  label-for="vi-first-name"
                >
                  <DatePicker
                    ref="updateDate"
                    :date="inputs.dateStart"
                    :type="'start'"
                    :status="false"
                    @setDatePicker="onSetDatePicker"
                  />
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group
                  label="สิ้นสุด"
                  label-for="vi-first-name"
                >
                  <DatePicker
                    ref="updateDateEnd"
                    :date="inputs.dateEnd"
                    :type="'end'"
                    :status="false"
                    @setDatePicker="onSetDatePicker"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xl-4  align-self-center">
            <div
              class="d-flex justify-content-center justify-content-sm-start"
              style="margin-top:5px;"
            >
              <b-button
                :variant="setvariant.month ? outline[0] : outline[1]"
                class="border-primary btBetween"
                size="sm"
                @click="onSetbetween('m')"
              >เดือนนี้
              </b-button>
              <b-button
                :variant="setvariant.year ? outline[0] : outline[1]"
                class="border-primary btBetween"
                size="sm"
                @click="onSetbetween('y')"
              >ปีนี้
              </b-button>
            </div>
          </div>
          <div class="col-12  align-self-center ">
            <b-form-group
              label="สินค้า"
              label-for="vi-first-name"
            >
              <multiselect
                v-model="valueProduct"
                tag-placeholder="Add this as new tag"
                placeholder="ระบุสินค้า"
                label="name"
                track-by="code"
                :options="options"
                :multiple="true"
                @tag="addTag"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12 col-sm-6 align-self-center">
            <b-form-group
              label="ช่องทางการขาย"
              label-for="vi-first-name"
            >
              <multiselect
                v-model="valuePayment"
                tag-placeholder="Add this as new tag"
                placeholder="ระบุช่องทางการขาย"
                label="name"
                track-by="code"
                :options="optionsPayment"
                :multiple="true"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-6 align-self-center">
            <b-form-group
              label="ประเทศ"
              label-for="vi-first-name"
            >
              <multiselect
                v-model="valueCountries"
                tag-placeholder="Add this as new tag"
                placeholder="ระบุประเทศ"
                label="name"
                track-by="code"
                :options="optionsCountries"
                :multiple="true"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-3 ">
            <div
              class="card earnings-card bg-primary"
              style="    height: 130px;
"
            >
              <div
                class="card-body"
                style="cursor:pointer"
              >
                <b-media no-body>
                  <b-media-body class="my-auto fzText">
                    <b-card-text
                      class="font-small-3 mb-1"
                      style="color: white;"
                    >
                      ยอดขาย
                    </b-card-text>
                    <div class="d-flex">
                      <h4
                        v-if="dataCard.totalSale < 10000"
                        class="font-weight-bolder mb-0  text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalSale | toAmount }}
                      </h4>
                      <h4
                        v-if="dataCard.totalSale > 10000"
                        class="font-weight-bolder mb-0  text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalSale | toAbbreviate }}
                      </h4>
                      <h4 style="color: white;">
                        ฿
                      </h4>
                    </div>
                    <font-awesome-icon
                      class="icon-bg"
                      :icon="['fa', 'arrow-right-arrow-left']"
                    />
                  </b-media-body>
                </b-media>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3 ">
            <div
              class="card earnings-card bg-secondary"
              style="    height: 130px;
"
            >
              <div class="card-body">
                <b-media no-body>
                  <b-media-body class="my-auto fzText">
                    <b-card-text
                      class="font-small-3 mb-1"
                      style="color: white;"
                    >
                      {{ "ต้นทุน" }}
                    </b-card-text>
                    <div class="d-flex">
                      <h4
                        v-if="dataCard.totalCost < 10000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalCost | toAmount }}
                      </h4>
                      <h4
                        v-if="dataCard.totalCost > 10000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalCost | toAbbreviate }}
                      </h4>
                      <h4 style="color: white;">
                        ฿
                      </h4>
                    </div>
                    <font-awesome-icon
                      class="icon-bg"
                      :icon="['fa', 'circle-dollar-to-slot']"
                    />
                  </b-media-body>
                </b-media>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3 ">
            <div
              class="card earnings-card bg-success"
              style="    height: 130px;
"
            >
              <div class="card-body">
                <b-media no-body>
                  <b-media-body class="my-auto fzText">
                    <b-card-text
                      class="font-small-3 mb-1"
                      style="color: white;"
                    >
                      {{ "กำไร" }}
                    </b-card-text>
                    <div class="d-flex">
                      <h4
                        v-if="dataCard.totalProfit < 10000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalProfit | toAmount }}
                      </h4>
                      <h4
                        v-if="dataCard.totalProfit > 10000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalProfit | toAbbreviate }}
                      </h4>
                      <h4 style="color: white;">
                        ฿
                      </h4>
                    </div>
                    <font-awesome-icon
                      class="icon-bg"
                      :icon="['fa', 'baht-sign']"
                    />
                  </b-media-body>
                </b-media>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-3 ">
            <div
              class="card earnings-card bg-info"
              style="    height: 130px;
"
            >
              <div class="card-body">
                <b-media no-body>
                  <b-media-body class="my-auto fzText">
                    <b-card-text
                      class="font-small-3 mb-1"
                      style="color: white;"
                    >
                      {{ "กำไรสุทธิ" }}
                    </b-card-text>
                    <div class="d-flex">
                      <h4
                        v-if="dataCard.totalNetProfit < 10000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalNetProfit | toAmount }}
                      </h4>
                      <h4
                        v-if="dataCard.totalNetProfit > 10000"
                        class="font-weight-bolder mb-0 text-truncate mr-1"
                        style="color: white;"
                      >
                        {{ dataCard.totalNetProfit | toAbbreviate }}
                      </h4>
                      <h4 style="color: white;">
                        ฿
                      </h4>
                    </div>

                    <feather-icon
                      class="icon-bg"
                      :icon="'DollarSignIcon'"
                    />
                  </b-media-body>
                </b-media>
              </div>
            </div>
          </div>
        </div>
        <div class="row align-items-center mb-1">
          <div class="col-6 col-sm-3 col-xl-2 ">
            <b-form-checkbox v-model="inputs.checkboxProfit10">
              กำไร 10 %
            </b-form-checkbox>
          </div>
          <div class="col-6 col-sm-3 col-xl-2 ">
            <b-form-checkbox v-model="inputs.checkboxProfit20">
              กำไร 20 %
            </b-form-checkbox>
          </div>
          <div class="col-6 col-sm-3 col-xl-2 ">
            <b-form-checkbox v-model="inputs.checkboxCountry">
              ประเทศ
            </b-form-checkbox>
          </div>
          <div class="col-6 col-sm-3 col-xl-2 ">
            <b-form-checkbox v-model="inputs.checkboxPayment">
              ช่องทางการขาย
            </b-form-checkbox>
          </div>
          <div
            v-if="listOrder.length > 0"
            class="col-12 col-sm-6 col-xl-4 mt-1 mt-xl-0 text-xl-right"
          >
            <div>
              <JsonExcel
                id="fileDowload"
                :data="jsonData"
                :fields="fieldData"
                :name="`${moment(inputs.dateStart).format('DD-MM-YYYY')}:${moment(inputs.dateEnd).format('DD-MM-YYYY')}.xls`"
                type="xls"
                class="d-none"
              >
                Download Data
              </JsonExcel>
            </div>
            <b-button
              :variant="'info'"
              class="border-info"
              @click="chooseFiles"
            >
              ออกรายงาน
            </b-button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-table
              striped
              hover
              responsive
              :per-page="inputsnode.perPage"
              :current-page="inputsnode.currentPage"
              :items="listOrder"
              :fields="fields"
              :filter="searchTerm"
              show-empty
              @filtered="onFiltered"
              @row-clicked="myRowClickHandler"
            >
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(date)="data">
                {{ data.item.created_date | formatDateSort }}
              </template>
              <template #cell(costqtybox)="data">
                <div
                  v-for="(box, index) in data.item.box"
                  :key="index"
                  style="margin-top:5px"
                >
                  {{ parseFloat(box.cost.$numberDecimal) | toCurrency }} /
                  {{ box.amount | toAmount }} / {{ box.boxDetail.no }}
                </div>
              </template>
              <template #cell(Price)="data">
                <div>
                  <p :id="`tooltip-target-1${data.index}`">
                    {{ data.item.PriceSale | toCurrency }}
                  </p>
                  <b-tooltip
                    :target="`tooltip-target-1${data.index}`"
                    triggers="hover"
                    placement="right"
                  >
                    <div class="mt-1">
                      <p
                        v-if="data.item.listProduct.promotion > 0"
                        style="    margin-top: -10px;"
                      >
                        โปรโมชั่น
                        {{ data.item.listProduct.promotion | toCurrency }}%
                      </p>
                      <p
                        v-if="data.item.listProduct.discount > 0"
                        style="    margin-top: -10px;
"
                      >
                        ส่วนลด
                        {{ data.item.listProduct.discount | toCurrency }}%
                      </p>
                      <p
                        v-if="
                          parseFloat(
                            data.item.listProduct.specialDiscount.$numberDecimal
                          ) > 0
                        "
                        style="    margin-top: -10px;
"
                      >
                        ส่วนลดพิเศษ
                        {{
                          parseFloat(
                            data.item.listProduct.specialDiscount.$numberDecimal
                          ) | toCurrency
                        }}
                        บาท
                      </p>
                    </div>
                  </b-tooltip>
                </div>
              </template>
              <template #cell(totalSale)="data">
                {{ data.item.PricesaleBox | toCurrency }}
              </template>
              <template #cell(totalCost)="data">
                {{ data.item.totalCost | toCurrency }}
              </template>
              <template #cell(Profit10)="data">
                {{ data.item.Profit10 | toCurrency }}
              </template>
              <template #cell(Profit20)="data">
                {{ data.item.Profit20 | toCurrency }}
              </template>
              <template #cell(Profit)="data">
                {{ data.item.NetProfit | toCurrency }}
              </template>
              <template #cell(Country)="data">
                {{ data.item.country.thName }}
                <!-- {{ data.item.NetProfit | toCurrency }} -->
              </template>
              <template #cell(Payment)="data">
                {{ data.item.payment.name }}

                <!-- {{ data.item.NetProfit | toCurrency }} -->
              </template>
              <template #empty>
                <div class="col-md-12 text-center m-1">
                  <h4>ไม่พบข้อมูลการขาย</h4>
                </div>
              </template>
              <template #emptyfiltered>
                <div class="col-md-12 text-center m-1">
                  <h4>ไม่พบข้อมูลการขาย</h4>
                </div>
              </template>
            </b-table>
            <b-card-body
              class="d-flex justify-content-between flex-wrap pt-0 mt-2"
            >
              <b-form-group
                label="Per Page"
                label-for="sortBySelect"
                label-class="mr-1"
                class="d-flex"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="inputsnode.perPage"
                  size="sm"
                  inline
                  :options="inputsnode.pageOptions"
                />
              </b-form-group>

              <div>
                <b-pagination
                  v-model="inputsnode.currentPage"
                  :total-rows="inputsnode.totalRows"
                  :per-page="inputsnode.perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JsonExcel from 'vue-json-excel'
import { defineComponent } from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect, BTable, BPagination, BTooltip

} from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import moment from 'moment'

import momentimezone from 'moment-timezone'
import Multiselect from 'vue-multiselect'
import DatePicker from '@/views/components/production/datePicker.vue'

export default defineComponent({
  name: 'dashboard_income',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BInputGroupPrepend,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BInputGroup,
    DatePicker,
    BFormSelect,
    AppEchartBar,
    moment,
    BTable,
    BPagination,
    BTooltip,
    Multiselect,
    JsonExcel
  },
  created () {
    this.loaderProduct()
    this.loaderCountries()
    this.loaderPayment()
    this.loader()
  },
  computed: {
  },
  watch: {
    'inputs.checkboxProfit10': {
      handler (val) {
        this.fields[7].tdClass = !val ? 'p-0 d-none' : 'p-0 text-right'
        this.fields[7].thClass = !val ? 'd-none' : 'text-end text-nowrap'
        this.setlocalCheckbox()
      }
    },
    'inputs.checkboxProfit20': {
      handler (val) {
        this.fields[8].tdClass = !val ? 'p-0 d-none' : 'p-0 text-right'
        this.fields[8].thClass = !val ? 'd-none' : 'text-end text-nowrap'
        this.setlocalCheckbox()
      }
    },
    'inputs.checkboxCountry': {
      handler (val) {
        this.fields[10].tdClass = !val ? 'p-0 d-none' : 'p-0 text-center'
        this.fields[10].thClass = !val ? 'd-none' : 'text-center text-nowrap'
        this.setlocalCheckbox()
      }
    },
    'inputs.checkboxPayment': {
      handler (val) {
        this.fields[11].tdClass = !val ? 'p-0 d-none' : 'p-0 text-center'
        this.fields[11].thClass = !val ? 'd-none' : 'text-center text-nowrap'
        this.setlocalCheckbox()
      }
    },
    'inputs.dateStart': {
      handler (val) {
        this.loader()
      }
    },
    'inputs.dateEnd': {
      handler (val) {
        this.loader()
      }
    },
    valueProduct (val) {
      this.loader()
    },
    valueCountries (val) {
      this.loader()
    },
    valuePayment (val) {
      this.loader()
    }
  },
  methods: {
    setlocalCheckbox () {
      localStorage.setItem('setcheckbox', JSON.stringify({
        checkProfit10: this.inputs.checkboxProfit10,
        checkProfit20: this.inputs.checkboxProfit20,
        checkboxCountry: this.inputs.checkboxCountry,
        checkboxPayment: this.inputs.checkboxPayment
      }))
    },
    myRowClickHandler (record, index) {
      this.onClickRouterDetailorder(record._id)
    },
    onClickRouterDetailorder (id) {
      this.$router.push({ path: `/ordermanagement/${id}` })
    },
    chooseFiles () {
      document.getElementById('fileDowload').click()
    },
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.options.push(tag)
      this.valueProduct.push(tag)
    },
    async loaderProduct () {
      const productAll = await this.$store.dispatch('production/getProductAll')
      this.options = []
      productAll.data.items.forEach(data => {
        this.options.push(
          { name: data.product.name, code: data.product._id }
        )
      })
    },
    async loaderCountries () {
      const CountriesAll = await this.$store.dispatch('customer/getCountry')
      this.optionsCountries = []
      CountriesAll.data.items.forEach(data => {
        this.optionsCountries.push(
          { name: data.thName, code: data._id }
        )
      })
    },
    async loaderPayment () {
      const PaymentAll = await this.$store.dispatch('setting/getAllChannel')
      console.log('CountriesAll', PaymentAll)
      this.optionsPayment = []
      PaymentAll.data.items.forEach(data => {
        this.optionsPayment.push(
          { name: data.name, code: data._id }
        )
      })
    },
    toCurrencyFormat (value) {
      let dataVal = value
      if (typeof dataVal !== 'number') {
        dataVal = parseFloat(dataVal)
      }
      const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
      return formatter.format(dataVal)
    },
    toAmountFormat (value) {
      let dataVal = value
      if (typeof dataVal !== 'number') {
        dataVal = parseFloat(dataVal)
      }
      const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 0 })
      return formatter.format(dataVal)
    },
    async loader () {
      if (localStorage.getItem('setcheckbox')) {
        const setcheckbox = JSON.parse(localStorage.getItem('setcheckbox'))
        this.inputs.checkboxProfit10 = setcheckbox.checkProfit10
        this.inputs.checkboxProfit20 = setcheckbox.checkProfit20
        this.inputs.checkboxCountry = setcheckbox.checkboxCountry
        this.inputs.checkboxPayment = setcheckbox.checkboxPayment
      }
      const productList = this.valueProduct.map(x => x.code)
      const countryList = this.valueCountries.map(x => x.code)
      const paymentList = this.valuePayment.map(x => x.code)
      const data = await this.$store.dispatch('dashboard/getDataRePort', { startDate: this.inputs.dateStart, endDate: this.inputs.dateEnd, productList, countryList, paymentList })
      if (data.data.success) {
        this.listOrder = []
        data.data.order_list.forEach(data => {
          let totalCost = 0
          let totalProfit = 0
          const price = parseFloat(data.listProduct.price.$numberDecimal)
          const percen = parseFloat(data.listProduct.promotion)
          const discount = parseFloat(data.listProduct.discount)
          const specialDiscount = parseFloat(data.listProduct.specialDiscount.$numberDecimal)
          const PriceSale = ((price - specialDiscount) - ((price / 100) * (percen + discount)))
          let amountBox = 0
          data.box.forEach(box => {
            totalCost += (parseFloat(box.cost.$numberDecimal) * box.amount)
            amountBox += box.amount
          })
          const PricesaleBox = PriceSale * amountBox
          totalProfit = parseFloat(PricesaleBox) - totalCost
          const Profit10 = (totalProfit / 100) * 10
          const Profit20 = (totalProfit / 100) * 20
          const NetProfit = totalProfit - ((totalProfit / 100) * 30)

          this.listOrder.push({ ...data, ...{ totalCost }, ...{ totalProfit }, ...{ PriceSale }, ...{ Profit10 }, ...{ Profit20 }, ...{ NetProfit }, ...{ PricesaleBox } })
        })
        this.dataCard.totalSale = 0
        this.dataCard.totalCost = 0
        this.dataCard.totalProfit = 0
        this.dataCard.totalNetProfit = 0
        this.listOrder.forEach(data => {
          this.dataCard.totalSale += parseFloat(data.PricesaleBox)
          this.dataCard.totalCost += parseFloat(data.totalCost)
          this.dataCard.totalProfit += parseFloat(data.totalProfit)
          this.dataCard.totalNetProfit += parseFloat(data.NetProfit)
        })
        this.jsonData = []
        this.listOrder.forEach((data, i) => {
          let CostQtyBox = ''
          data.box.forEach(box => {
            CostQtyBox += CostQtyBox === '' ? `${this.toCurrencyFormat(box.cost.$numberDecimal)} / ${this.toAmountFormat(box.amount)} / ${box.detail}` : `\n ${this.toCurrencyFormat(box.cost.$numberDecimal)} / ${this.toAmountFormat(box.amount)} / ${box.detail}`
          })
          const paymentMethod = data.transaction[0] === undefined ? null : data.transaction[0].type
          this.jsonData.push(
            {
              index: i + 1,
              date: moment(data.created_at).format('DD/MM/YYYY'),
              product: data.product.name,
              CostQtyBox,
              // cost: 'ต้นทุน',
              price: this.toCurrencyFormat(data.PriceSale),
              // amount: 'จำนวนชิ้น',
              // boxNumber: 'เลขที่ลัง',
              totalPrice: this.toCurrencyFormat(data.PricesaleBox),
              totalCost: this.toCurrencyFormat(data.totalCost),
              price10: this.toCurrencyFormat(data.Profit10),
              price20: this.toCurrencyFormat(data.Profit20),
              total: this.toCurrencyFormat(data.NetProfit),
              country: data.country.thName,
              payment: data.payment.name,
              // eslint-disable-next-line no-nested-ternary
              paymentMethod: paymentMethod === null ? '-' : paymentMethod === 'm' ? 'mobile banking' : paymentMethod === 'd' ? 'ฝากเงินตู้ atm' : paymentMethod === 't' ? 'โอนเงินผ่านตู้ atm' : 'เก็บเงินปลายทาง',
              note: data.description
            }
            // m = mobile banking, d = ฝากเงินตู้ atm, t = โอนเงินผ่านตู้ atm , c = เก็บเงินปลายทาง
          )
        })
        console.log('listOrder', this.listOrder)
        this.inputsnode.totalRows = this.listOrder.length
      }
    },
    onFiltered (filteredItems) {
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    onSetDatePicker (val) {
      if (val.type === 'start') { this.inputs.dateStart = String(val.val) } else { this.inputs.dateEnd = String(val.val) }
    },
    onSetbetween (val) {
      this.setvariant = {
        month: false,
        year: false
      }
      if (val === 'm') {
        this.setvariant.month = true
        this.inputs.dateStart = moment().format('YYYY-MM-01')
        this.inputs.dateEnd = moment().format('YYYY-MM-DD')
      } else {
        this.setvariant.year = true
        this.inputs.dateStart = moment().format('YYYY-01-01')
        this.inputs.dateEnd = moment().format('YYYY-MM-DD')
      }
      this.$refs.updateDate.setdate(this.inputs.dateStart)
      this.$refs.updateDateEnd.setdate(this.inputs.dateEnd)
    },
    exportTable () {
      console.log(JsonExcel)
    }
  },
  data () {
    return {
      moment,
      setvariant: {
        month: true,
        year: false
      },
      inputs: {
        dateStart: moment().format('YYYY-MM-01'),
        dateEnd: moment().format('YYYY-MM-DD'),
        checkboxBoxes: false,
        checkboxProfit10: false,
        checkboxProfit20: false,
        checkboxCountry: true,
        checkboxPayment: true

      },
      valueProduct: [
      ],
      options: [],
      valueCountries: [
      ],
      optionsCountries: [],
      valuePayment: [
      ],
      optionsPayment: [],
      outline: ['primary', 'outline-primary', 'outline-primary'],
      listOrder: [],
      searchTerm: '',
      dataCard: {
        totalSale: 0,
        totalCost: 0,
        totalProfit: 0,
        totalNetProfit: 0

      },
      fields: [
        {
          key: 'index',
          label: 'ลำดับ',
          tdClass: '',
          thClass: 'text-nowrap'
        },
        {
          key: 'date',
          label: 'ว/ด/ป',
          tdClass: 'p-0 text-start text-nowrap p-1',
          thClass: 'text-nowrap',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => moment(item.created_date).valueOf()
        },
        {
          key: 'product.name',
          label: 'สินค้า',
          tdClass: 'p-0 text-nowrap p-1',
          thClass: 'text-nowrap'
        },
        {
          key: 'costqtybox',
          label: 'ต้นทุน/จำนวน/ลัง',
          tdClass: 'p-0',
          thClass: 'text-nowrap'
        },
        {
          key: 'Price',
          label: 'ราคา',
          tdClass: 'p-0 text-center',
          thClass: 'text-right text-nowrap',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.PriceSale
        },
        {
          key: 'totalSale',
          label: 'รวมยอดขาย',
          tdClass: 'p-0 text-center',
          thClass: 'text-right text-nowrap',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.PricesaleBox
        },
        {
          key: 'totalCost',
          label: 'รวมต้นทุน',
          tdClass: 'p-0 text-center',
          thClass: 'text-right text-nowrap',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.totalCost
        },
        {
          key: 'Profit10',
          label: 'กำไร 10 %',
          tdClass: 'p-0 d-none text-right',
          thClass: 'd-none text-end text-nowrap',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.Profit10
        },
        {
          key: 'Profit20',
          label: 'กำไร 20 %',
          tdClass: 'p-0 d-none text-right',
          thClass: 'd-none text-end text-nowrap',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.Profit20
        },
        {
          key: 'Profit',
          label: 'กำไรสุทธิ',
          tdClass: 'p-0 text-right',
          thClass: 'text-end text-nowrap',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.NetProfit
        },
        {
          key: 'Country',
          label: 'ประเทศ',
          tdClass: 'p-0 text-center',
          thClass: 'text-nowrap'
        },
        {
          key: 'Payment',
          label: 'ช่องทาง',
          tdClass: 'p-0 text-center',
          thClass: 'text-nowrap'
        }
      ],
      inputsnode: {
        perPage: null,
        pageOptions: [{
          value: null,
          text: 'ทั้งหมด'
        }, 10, 50, 100, 500],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      },
      jsonData: [],
      fieldData: {
        ลำดับ: 'index',
        วันที่: 'date',
        สินค้า: 'product',
        'ต้นทุน/จำนวน/ลัง': 'CostQtyBox',
        ราคาขาย: 'price',
        รวมยอดขาย: 'totalPrice',
        รวมต้นทุน: 'totalCost',
        กัน10: 'price10',
        กัน20: 'price20',
        รวมกำไร: 'total',
        ประเทศ: 'country',
        ช่องทางขาย: 'payment',
        วิธีชำระเงิน: 'paymentMethod',
        หมายเหตุ: 'note'
      }
    }
  }
})
</script>
<style scoped>
.btBetween {
  width: 130px;
  height: 2.5rem !important;
  height: 25px;
  margin-right: 3px;
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .fzText h4 {
    font-size: 1.5rem !important;
  }
  .fzText p {
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 900px), (max-width: 600px) {
  .fzText h4 {
    font-size: 3rem !important;
  }
  .fzText p {
    font-size: 1.5rem !important;
  }
}

.wdrs {
  width: 100% !important;
  overflow-x: scroll;
  min-width: 900px !important;
}

.icon-bg {
  position: absolute;
  right: -14px;
  top: 6px;
  opacity: 0.4;
  transition: all 0.3s ease;
  width: 120px;
  height: 120px;
  color: white;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
